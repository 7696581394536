.responsive {
    max-width: 100%; /* Ensure the image doesn't exceed its parent's width */
    height: auto; /* Maintain the image's aspect ratio */
    /* position: fixed;
    top: 0px;
    z-index: -90; */
  }
  .womenImg{
    max-width: 100%;
    height: auto;
  }
  .imageContainer img{
    max-width: 100%;
    height:700px;
  }
  .womenimgContainer img{
    max-width: 100%;
    height: auto;
  }
  .form-container {
    display: flex;
    justify-content: space-around;

    margin-top: 4rem;
}

.form-text h5 {
    font-size: 1rem;
}
.MuiInputBase-root{
  margin-bottom: 0px!important;
}
.MuiStack-root{
  margin-bottom: 0px!important;
}
.form-text p {
    font-size: 0.8rem;
}
.form-section .form-input1 {
  padding-left: 10px;
  margin-right: 10px;
  display: flex;
  gap: 20px;
}

.form-input1 input {
  width: 50%;
  height: 35px;
  margin-top: 0.7rem;

}

.form-section .form-input2 {
  padding-left: 10px;
  margin-right: 10px;
  display: flex;
  gap: 20px;
}

.form-input2 input {
  width: 50%;
  height: 35px;
  margin-top: 0.7rem;
}
.form-section .form-input3 {
  padding-left: 10px;
  margin-right: 10px;
  display: flex;
  gap: 20px;
}

.form-input3 input {
  width: 50%;
  height: 35px;
  margin-top: 0.7rem;
}

.form-section .form-input4 {
  width: 98%;
  height: 7rem;
  margin-top: 1rem;
  margin-left: 5px;
}

.btn-text-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.btn-text {
  background-color: #323232;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-top: 1rem;
}

@media (max-width: 400px) {
    

  .form-text h5 {
      font-size: 15px;
  }

  

  

  



}
@media (max-width: 600px) {
    
  .form-text h5 {
      font-size: 10px;
  }

  .form-text p {
      font-size: 8px;
  }

  .form-container {
      display: grid;
      grid-template-columns: 1fr;

  }
  /* YourComponent.css */
.form-section {
  width: 600px;
}

.form-input1,
.form-input2,
.form-input3 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}


  .form-container1 {
      max-width: 100%;
      margin-left: 10px;
      
      margin-right: 60px;
      
  }

  .form-section .form-input1 {
      padding-left: 6px;
      margin-right: 70px;
      display: flex;
  }

  .form-section .form-input2 {
      padding-left: 6px;
      margin-right: 70px;
      display: flex;
  }

  .form-section .form-input3 {
      padding-left: 6px;
      margin-right: 70px;
      display: flex;
  }

  .form-section .form-input4 {
      width: 87%;
      height: 7rem;
      margin-top: 1rem;
      margin-left: 6px;
  }

  .btn-text {
      margin-top: 2rem;
      margin-right: 8rem;
  }

  .img-container {
      margin-right: 10rem;
      margin-left: 5rem;
      margin-top: 1rem;
  }

  .img-container img {}

  

 

  




}
  /* .MuiBox-root{
    background-color: transparent !important;
    justify-content: space-between !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important;
  }
  .input_main_div{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .MuiFormControl-root,.MuiGrid-root{
    width: 49% !important;
  }
.MuiGrid-root .MuiFormControl-root{
  width: 100% !important;
}
.city_input{
  width: 49%;
}
.city_input .MuiFormControl-root{
  width: 100% !important;
}
.women_main{
  display: flex;
}
.womenimgContainer,.women_main_right{
  width: 49%;
}
.women_main_right{
  padding:20px ;
} */
.MuiStack-root {
  padding-top: 4px !important;
}

.background {
  /* background-image: url('https://media.istockphoto.com/id/947690336/photo/blue-splash-of-water-in-watercolor.jpg?s=2048x2048&w=is&k=20&c=f9IDRA3WBxS4ZY3Xt5ZP0GotZ5hjSBlufJEMxFmC7pM='); */

  /* width:100%; */
  /* background-color: rebeccapurple; */
  /* width: 1600px;;
  justify-content: center;
  display: flex;
  align-items: center; */
  /* background-color: rebeccapurple; */
  z-index:99;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appointmentContainer{
  /* margin-top: 50px; */
  /* margin-bottom: 10px; */
  /* margin-top: 280px; */
}
@media screen and(max-width:600px) {
  .appointmentContainer{
    /* margin-top: 1px;
    margin-bottom: 1px; */
  }
  
}
.callus{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rebeccapurple; */
  width: 100%;
  font-size: 15px;
  font-weight: 400px;
  background-color: white;

}
.ortext{
  color: black;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
/* background-color: rebeccapurple; */
margin-top: 16px;
font-size: 18px;

/* margin-left: 10px; */
}
.ortextcall{
  color:#d3af37;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
margin: 0;
/* margin-bottom: 10px; */
/* background-color: rebeccapurple; */
font-size: 15px;
font-weight: 400;
margin-left: 10px;

}
.phoneno{

  color:black;
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;

  
}
.formContainer{
  /* display: flex;
  justify-content: center;
  align-items: center; */
  background-color: whitesmoke;
  /* width: 100%; */

}
.forminnnerContainer{
  width:100%;

}
.ourbooking{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-size: 16px;
  color: #323232;

}
.appointment{
  color: "#383939";
  font-size: 29px;
  font-weight: 400;
}

.bookingtext{
  /* style={{ fontSize: 17, color: "#626A70" }} */
  font-size: 17px;
  color: #626A70;
}
@media screen and (max-width:600px) {
  .ortextcall{
    color:#d3af37;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  /* margin-bottom: 10px; */
  /* background-color: rebeccapurple; */
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
  flex-direction: column;

  
  }
  .ourbooking{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  
  }
 
} 
.bookappointmentsalon{
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.discriptionText{
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  text-align: center;
  font-family: "Poppins";
}


/* style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0px' }} */


.formContainer{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  
  }
  @media screen and (max-width:600px) {
    .bookappointmentsalon{
      font-size: 30px;
      margin-bottom: 20px;
      font-weight: 600;
      text-align: center;
      /* display: flex;
      justify-content: center;
      align-items: center; */
      /* padding: 20px; */
    }
    
  }
  .onlinetext{
    font-size: 30px;
    color: #323232;
    font-weight:500;
    font-family:Poppins ;
    text-align: center;
   
  }
  .bookappointment{
display: flex;
justify-content: center;
align-items: center;
background-color: white;
margin-top: 10px;
flex-direction: column;
  }

@media screen and(max-width:600px) {
 
.formContainer{
  padding: 0px;

}


}
.formContainer{
  padding: 60px;

}
@media screen and (max-width:600px) {
 
.appointmentimg{
  width: 300px;
}
.responsive {
max-width: 100%;
height:380;
}
  
}
@media (max-width: 768px) {
  .appointmentimg {
    width: 350px;
  }
  .bookingtext{
    padding: 10px;
  }
}
@media (min-width:769px) and (max-width:1200px){
  .appointmentimg{
    margin-left: 140px;
  }
}





