/* .  style={{
    backgroundColor: "#626A70",
       paddingLeft: 5,
       paddingRight: 5,
       paddingTop: 6,
       paddingBottom: 10,
     }} */
     .graphic-background-grey {
      
     
     
      pointer-events: none;
 
      background-image: radial-gradient(circle,rgba(191,191,191,.3) .325rem,rgba(59, 58, 63, 0) .325rem);
      background-size: 2.5rem 2.5rem;
    
     
  }
     .headofficestyle{
        /* background:"#626A70" ; */
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 6px;
        padding-bottom: 10px;
        background: #626A70;
     }
     @media screen and(max-width:600px) {
        .headofficestyle{
            background:"#626A70" ;
           
         }
     }