.iconimgcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

}
.aboutcontainertext{
    font-size: 35px;
    font-weight: 500;
    color:#323232;
    padding:0px;

}
@media screen and (max-width:600px) {
    .aboutcontainertext{
        font-size: 25px;
        font-weight: 500;
        color:#323232;
        padding:0px;
    
    }
}

/* //   style={{
    //     fontSize: 42,
    //     fontWeight: '500',
    //     color: '#323232',
    //     fontFamily: "Poppins"
        
    //   }} */