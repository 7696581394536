.salonimg{
    height: 150px;
    width: 200px;
}

/* style={{width: 'calc(100% / 3 - 20px)',}} */
@media (max-width: 767px) {
    .cardContainer{
        /* width: ; */
        width: calc(100% / 4 - 20px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media (min-width: 768px) {
        .cardContainer{
            /* width: ; */
            width: calc(100% / 2 - 10px);
            display: flex;
            /* justify-content: center;
            align-items: center;
            background-color: aqua; */
          }
       
      
      }
      @media (min-width: 768px) {
        .cardContainer{
            /* width: ; */
            width: calc(100% / 2 - 10px);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: aqua;
          }
       
      
      }
      /* .css-1v4vz67-MuiPaper-root{
        margin-top:15px;
        background-color: rebeccapurple;
      } */
.video-container{
  height: auto;
  width: 100%;
  background-color:gainsboro;
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.videoContainer {
  position: relative;
  width: 100%;
  height: 150px;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (height:width) */
  overflow: hidden;
}
.salontypeText{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}
.salonName{
  margin-top: 190px;
  display: flex;
  justify-content: center;
  align-items: center;


}
.salonHeading2{
  margin: 10px auto 15px;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  max-width: 300px;
  /* color: #323232; */
  text-align: center;
  color: rebeccapurple;
  
}
.paratag{
  color: #323232;
  font-size: 18px;
  max-width: 900px;

}
.bulletsStyle{
  
}
.queryStyle{
  display: flex;
  justify-content: center;
  align-items: center;
}
.franchiseButton{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.button{
  background-color:#323232 ;
 width: 180px;
 display: flex;
 align-items: center;
 justify-content: center;
 /* border-radius: 60px solid red; */
 border-width: 1px;
}
.franchise{
  font-size: 30px;
  font-weight: 600;
  color: #323232;
}
.enqury{
  font-size: 16px;
  color: white;

}
.videostyle{
  height:600px
}

.smartsalonvideocontainer{
  margin-top: 80px;
}
.videodatacontiner{
  display: flex;
  justify-content: center;
  align-items: center;
}
.beautysalon{
  color: #323232;
 
  line-height: 30px;
  font-weight: 400;

}


/* style={{display:'flex',justifyContent:'center',alignItems:'center'}} */
@media screen and (max-width:600px) {
  .videostyle {
    height: 268px;
    width: 380px;
    max-width: 100%;
}
.beautysalon{
  color: #323232;
  font-size: 18px;
 

  line-height: 30px;
  font-weight: 400;
  padding:10px

}
.videodatacontiner{
  display: flex;
  justify-content: center;
  align-items: center;
}
.smartsalonvideo{
  margin-top: 80px;
}
  .queryStyle{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .franchise {
    font-size: 17px;
    font-weight: 600;
    color: #323232;
    text-align: center;
}

}
/* style={{color:'#323232',fontSize:'18px',maxWidth:'900px',backgroundColor:''}} */

