@-webkit-keyframes sticky {
	0% {
		top: -100px;
	}

	100% {
		top: 0;
	}
}
.fixed-header {
	top: 0;
	left: 0;
	position: fixed;
	-webkit-animation: sticky 1s;
	animation: sticky 1s;
	-webkit-box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
	box-shadow: 0px 0px 30px 0px rgba(87, 95, 245, 0.1);
}
@keyframes sticky {
	0% {
		top: -100px;
	}

	100% {
		top: 0;
	}
}