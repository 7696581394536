@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Lora:ital,wght@0,400..700;1,400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sedan+SC&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn-hover {
  position: relative;
  display: inline-block;
  transition: color 0.3s ease-in-out;
}
.font-monrope{
  font-family: "Manrope", system-ui,sans-serif;

}


.btn-hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0%;
  height: 2px;
  background-color: black;
  transition: width 0.3s ease-in-out;
}
.foot-link {
 font-weight: 500;/* Ensure the width of the element is only as wide as the text */
}



.btn-hover:hover::after {
  width: 100%;
}

.btn-hover.active {
  color: black;
}

.btn-hover.active::after {
  width: 100%;
}

.text-black {
  color: black;
}

.link-text {

 font-family: "Monrope",sans-serif;
  font-weight: 600;
  letter-spacing: 0.05375rem;
  text-transform: uppercase;
 
  text-decoration: none;
}
.text-active {
  color: #ff6347; /* Change this color to your desired active tab color */
  transition: color 0.3s ease-in-out;
}


.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}


/* .roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
} */

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    background-color: white !important;
    color: #1a202c !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important; /* Ensures the background remains white */
  }
.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.transition-left {
  animation: slideFromLeft 0.9s ease;
}

.transition-right {
  animation: slideFromRight 0.9s ease;
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
