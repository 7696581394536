
.privacypolicy{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
@media screen and(max-width:600px) {
    .privacypolicy{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
}

/* style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} */